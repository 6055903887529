import React,{useState} from "react";
import {
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Spinner,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import {
  FiRefreshCw,
  FiPlay,
  FiStopCircle,
  FiEye,
  FiSettings,
} from "react-icons/fi";
import { DeleteIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom"; // Import useHistory
import SettingsModal from "../SettingsModal";
const GenerationTable = ({
  selectedProgram,
  selectedRow,
  currentStatus,
  fetchStatus,
  handleReExecuteContent,
  handleRetryContent,
  handleStopExecution,
  loadingReExecuteId,
  loadingRetryId,
  loadingStopId,
  stoppedExecutionId,
  confirmDelete,
  handleGenerateContent,
  isGenerating,
}) => {
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();
  const [viewMode, setViewMode] = useState(false);
  const [viewSettings, setViewSettings] = useState(null);
  const history = useHistory(); // Initialize useHistory
// console.log("GenerationTable selectedRow",selectedRow);

  if (
    !selectedRow ||
    !selectedRow.executions ||
    selectedRow.executions.length === 0
  ) {
    return null; // Return null to not show any message or content
  }

  const handleOpenSettings = (executionId, mode = false) => {
    const execution = selectedRow.executions.find(exec => exec.execution_id === executionId);
    if (execution) {
      setViewSettings(execution.used_execution_setting);
      setViewMode(mode);
      onSettingsOpen();
    }
  };

  const isExecutionComplete = (generatedResults) => {
    if (!generatedResults || generatedResults === 'N/A') return false;
    const [completed, total] = generatedResults.split('/').map(Number);
    return completed === total;
  };

  return (
    <Box p={8} pb={2} w="100%" align="center" justify="center">
      <Box
        width={{ base: "100%", md: "80%" }}
        bg="white"
        p={4}
        overflowY="auto"
        align="center"
        borderRadius="xl"
        borderColor="blackAlpha.200"
        borderWidth="1px"
        h="85vh"
      >
        <Table variant="simple" size="sm" w="100%">
          <Thead>
            <Tr>
              <Th textAlign="left">Timestamp</Th>
              <Th textAlign="left">Status</Th>
              {/* <Th textAlign="center">Re-Execute</Th> */}
              <Th textAlign="center">Resume and Finish</Th>
              <Th textAlign="center">Stop</Th>
              <Th textAlign="center">Generated Results</Th>
              <Th textAlign="center">Config</Th>
              <Th textAlign="center">View Results</Th>
              <Th textAlign="center">Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {selectedRow.executions.map((execution) => (
              <Tr key={execution.execution_id}>
                <Td textAlign="left">{execution.timestamp || "N/A"}</Td>
                <Td textAlign="left" display="flex" alignItems="center">
                  {currentStatus[execution.execution_id] ||
                    execution.status ||
                    "pending"}
                  <IconButton
                    icon={<FiRefreshCw />}
                    size="sm"
                    aria-label="Refresh Status"
                    bg="transparent"
                    onClick={() => fetchStatus(execution.execution_id)}
                    ml={0}
                  />
                </Td>
                {/* <Td textAlign="center">
                  <IconButton
                    icon={
                      loadingReExecuteId === execution.execution_id ? (
                        <Spinner size="sm" />
                      ) : (
                        <FiPlay />
                      )
                    }
                    color="green"
                    size="sm"
                    aria-label="Re-Execute"
                    bg="transparent"
                    onClick={() =>
                      handleReExecuteContent(execution.execution_id)
                    }
                    isDisabled={loadingReExecuteId === execution.execution_id}
                  />
                </Td> */}
                <Td textAlign="center">
                  <IconButton
                    icon={
                      loadingRetryId === execution.execution_id ? (
                        <Spinner size="sm" />
                      ) : (
                        <FiPlay />
                      )
                    }
                    color="green"
                    size="sm"
                    aria-label="Retry"
                    bg="transparent"
                    onClick={() => handleRetryContent(execution.execution_id)}
                    isDisabled={
                      loadingRetryId === execution.execution_id ||
                      isExecutionComplete(execution.generated_results)
                    }
                  />
                </Td>
                <Td textAlign="center">
                  <IconButton
                    icon={
                      loadingStopId === execution.execution_id ? (
                        <Spinner size="sm" />
                      ) : (
                        <FiStopCircle />
                      )
                    }
                    color="red" // Keep the color red regardless of the execution status
                    size="sm"
                    aria-label="Stop"
                    bg="transparent"
                    onClick={() => handleStopExecution(execution.execution_id)}
                    isDisabled={loadingStopId === execution.execution_id} // Disable only during loading
                  />
                </Td>
                <Td textAlign="center">
                  {execution.generated_results || "N/A"}
                </Td>
                <Td textAlign="center">
                  <IconButton
                    icon={<FiSettings />}
                    size="sm"
                    aria-label="Settings"
                    bg="transparent"
                    onClick={() => handleOpenSettings(execution.execution_id, true)}
                  />
                </Td>
                <Td textAlign="center">
                  <IconButton
                    icon={<FiEye />}
                    size="sm"
                    aria-label="View Results"
                    bg="transparent"
                    onClick={() =>
                      history.push(
                        `/program/generation/execution/${selectedProgram._id}/${selectedRow.file_unique_id}/${execution.execution_id}`
                      )
                    } // Redirect to the specified URL
                  />
                </Td>
                <Td textAlign="center">
                  <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    aria-label="Delete"
                    bg="transparent"
                    onClick={() => confirmDelete(execution.execution_id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={onSettingsClose}
        selectedRow={viewSettings} // Pass viewSettings instead of selectedRow
        viewMode={viewMode}
      />
    </Box>
  );
};

export default GenerationTable;
